export const TYPE_PROP = '@type'
export const ID_PROP = '@id'
export const CONTEXT_PROP = '@context'
export const GRAPH_PROP = '@graph'
export const AVAILABILITY = 'https://schema.org/InStock'
export const PRICE_SPECIFICATION_TYPE = 'UnitPriceSpecification'
export const PRICE_TYPE = 'https://schema.org/ListPrice'
export const ORGANIZATION = 'Organization'
export const OFFER = 'Offer'
export const CONTEXT = 'https://schema.org'
export const VISTAPRINT_TENANT = 'Vistaprint'
export const PRODUCT = 'Product'
export const BRAND = 'Brand'
