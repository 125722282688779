import { CONTEXT, CONTEXT_PROP, GRAPH_PROP } from './constants'
import { Product, Brand, Graph } from 'schema-dts'

export type ProductStructuredData = {
  name: string;
  sku: string;
  mpn: string;
  description: string;
  brand: Brand & { name: string };
  image: string[];
} &
  Product

export interface StructuredData {
  [CONTEXT_PROP]: string;
  [GRAPH_PROP]: ProductStructuredData[];
}

function buildGraph (graphData: any): Graph {
  return {
    [CONTEXT_PROP]: CONTEXT,
    [GRAPH_PROP]: [graphData],
  }
}

export default {
  buildGraph,
}
