import { PDPFaqs, PDPFaqsProps, pageHasFaqs } from '@vp/pdp-faqs'
import { Box } from '@vp/swan'
import React from 'react'

interface PDPFaqsWrapperProps {
  faqs: PDPFaqsProps | null;
}

export const PDPFaqsWrapper: React.FC<PDPFaqsWrapperProps> = ({ faqs }) => {
  return (
    <>
      {pageHasFaqs(faqs) && <Box marginTop='between-sections' />}
      <PDPFaqs {...faqs} />
    </>
  )
}
