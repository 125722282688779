import React, { useMemo } from 'react'
import structuredDataBuilder from './structuredDataBuilder'
import { Helmet } from 'react-helmet'
import { getSeoData as getSeoDataFaqs, PDPFaqsProps } from '@vp/pdp-faqs'

interface IProductJsonLdBottomProps {
  faqs: PDPFaqsProps | null;
}

export default function ProductJsonld (props: IProductJsonLdBottomProps) {
  const faqSeoDataGraph = useMemo(() => {
    const seoFaq = getSeoDataFaqs(props.faqs)
    return seoFaq ? structuredDataBuilder.buildGraph(seoFaq) : undefined
  }, [props.faqs])

  return (
    <Helmet>
      {faqSeoDataGraph && <script type='application/ld+json'>{JSON.stringify(faqSeoDataGraph, null, 2)}</script>}
    </Helmet>
  )
}
