import React from 'react'
import { DesignServicesPromoBannerWrapper } from './DesignServicesPromoBannerWrapper'
import { PDPFaqsWrapper } from './PDPFaqsWrapper'
import { PDPFaqsData } from '@vp/pdp-faqs/data'
import { DesignServicesPromoBannerData } from '@vp/design-services-promo-banner/data'
import ProductJsonLdBottom from './ProductJsonLd/ProductJsonLdBottom'

interface BottomPageProps {
  faqs: PDPFaqsData | null;
  designServicesPromoBannerData: DesignServicesPromoBannerData | null;
  locale: string;
  mpvId: string
}

const BottomPage: React.FC<BottomPageProps> = (props) => {
  return (
    <>
      <ProductJsonLdBottom
        faqs={props.faqs}
      />
      <DesignServicesPromoBannerWrapper
        data={props.designServicesPromoBannerData}
        locale={props.locale}
        mpvId={props.mpvId}
      />
      <PDPFaqsWrapper faqs={props.faqs} />
    </>
  )
}

export default BottomPage
