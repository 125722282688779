import React from 'react'
import {
  DesignServicesPromoBannerProps,
  DesignServicesPromoBanner,
  shouldRender,
} from '@vp/design-services-promo-banner'
import { Box } from '@vp/swan'
import { logDebug, logError, logWarning, logInfo } from '../infrastructure/browserLogger'

export const DesignServicesPromoBannerWrapper: React.FC<Omit<DesignServicesPromoBannerProps, 'logger'>> = (props) => {
  const designServicesPromoBannerProps: DesignServicesPromoBannerProps = {
    ...props,
    logger: {
      debug: logDebug,
      info: logInfo,
      warning: logWarning,
      error: logError,
    },
  }

  return (
    shouldRender(designServicesPromoBannerProps) && (
      <Box marginTop='between-sections'>
        <DesignServicesPromoBanner {...designServicesPromoBannerProps} />
      </Box>
    )
  )
}
