import { AxiosError, AxiosResponse } from 'axios'
import { BrowserLogger } from '@vp/front-end-logger'
import configuration from './configurationProvider'

const service = 'cheetah-product-page'

interface ILoggerContext {
  executionId?: string;
  [key: string | number]: any;
}

export function logAxiosError (error: AxiosError<any>, service: string): void {
  if (error.response) {
    logResponse(error.response, service)
    return
  }
  const message = 'The request has failed'
  SingletonBrowserLogger.instance
    .info(message, {
      statusText: error.message,
      method: error.config?.method,
      service,
      url: error.config?.url,
    })
    .catch((_e) => console.log('Error sending log'))
}

export type LogAction = (message: string, context?: ILoggerContext) => void

export function logError (message: string, context: ILoggerContext = {}): void {
  SingletonBrowserLogger.instance.error(message, context).catch((_e) => console.log('Error sending log'))
}

export function logInfo (message: string, context: ILoggerContext = {}): void {
  SingletonBrowserLogger.instance.info(message, context).catch((_e) => console.log('Error sending log'))
}

export function logWarning (message: string, context: ILoggerContext = {}): void {
  SingletonBrowserLogger.instance.warning(message, context).catch((_e) => console.log('Error sending log'))
}

export function logDebug (message: string, context: ILoggerContext = {}): void {
  SingletonBrowserLogger.instance.debug(message, context).catch((_e) => console.log('Error sending log'))
}

function logResponse (response: AxiosResponse<any>, service: string) {
  const message =
    response?.status >= 200 && response?.status <= 299
      ? 'The request was successfully executed'
      : 'The request has failed'
  SingletonBrowserLogger.instance
    .info(message, {
      status: response.status,
      statusText: response.statusText,
      method: response.config.method,
      service,
      url: response.config.url,
    } as ILoggerContext)
    .catch((_e) => console.log('Error sending log'))
}

class SingletonBrowserLogger {
  private static _instance: ILogger
  private constructor () {}

  public static get instance () {
    return (SingletonBrowserLogger._instance ??= configuration.isDevelopmentMode
      ? new DevLogger()
      : new BrowserLogger(service))
  }
}
class DevLogger implements ILogger {
  info (message: string, context?: ILoggerContext): Promise<void> {
    console.info(message, context)
    return Promise.resolve()
  }

  error (message: string, context?: ILoggerContext): Promise<void> {
    console.error(message, context)
    return Promise.resolve()
  }

  debug (message: string, context?: ILoggerContext): Promise<void> {
    console.debug(message, context)
    return Promise.resolve()
  }

  warning (message: string, context?: ILoggerContext): Promise<void> {
    console.warn(message, context)
    return Promise.resolve()
  }
}

interface ILogger {
  debug: (message: string, context?: ILoggerContext) => Promise<void>;
  info: (message: string, context?: ILoggerContext) => Promise<void>;
  warning: (message: string, context?: ILoggerContext) => Promise<void>;
  error: (message: string, context?: ILoggerContext) => Promise<void>;
}
