import React from 'react'
import { swanStyles } from '../components/page-bootstraping/SwanStyles'
import {
  ScreenClassProvider,
} from '@vp/swan'
import { PropsBottomProductData } from '../build/buildPagesData'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import BottomPage from '../components/BottomPage'

export interface DesignBottomPageProps {
  pageProps: PropsBottomProductData,
  experimentProps: {
    experimentId?: string;
    experimentKey?: string;
    variationId?: string;
    variationKey?: string;
  },
}

export default function UnityDesignBottom ({ pageProps, experimentProps }: DesignBottomPageProps) {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys(swanStyles)

  return (
    <>
      <ScreenClassProvider>
        <BottomPage {...pageProps.props.aggregatedData} locale={pageProps.props.locale} />
      </ScreenClassProvider>
    </>
  )
}
